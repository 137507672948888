<template>
    <div class="cart">
        <div class="goods-nb">共{{ list.length ? list.length : 0 }}件宝贝</div>
        <template v-for="(item, index) in list">
            <v-goods
                :goods="item"
                :index="index"
                :key="index"
                @delCart="delCart"
                style="margin-bottom: 0.2rem"
            />
        </template>
        <template v-if="empty">
            <v-empty type="cart" />
        </template>
        <div class="bottom-btn-box flex ai-c jc-sb">
            <div class="select-box flex ai-c" @click="allClick">
                <img
                    class="icon"
                    v-show="selectAll"
                    src="/img/icon-passed.png"
                    alt=""
                />
                <img
                    class="icon"
                    v-show="!selectAll"
                    src="/img/icon-circle.png"
                    alt=""
                />
                <div class="txt">{{ selectAll ? "取消" : "全选" }}</div>
            </div>
            <div class="btn-box flex ai-c">
                <div class="money flex fd-c ai-fd">
                    <div class="t flex ai-c">
                        <div class="lb">总计：</div>
                        <div class="m">¥{{ totalPrice.toFixed(2) }}</div>
                    </div>
                    <div class="t2">(不含运费)</div>
                </div>
                <div class="btn flex ai-c jc-c" @click="settlement">结算</div>
            </div>
        </div>
        <v-loveGoodsList />

        <v-tabbar :active="2" />
    </div>
</template>

<script>
import tabbar from "@/components/tabbar";
import loveGoodsList from "@/components/loveGoodsList";
import goods from "./goods";
import { cartList, delCart } from "../../api";
export default {
    components: {
        "v-tabbar": tabbar,
        "v-loveGoodsList": loveGoodsList,
        "v-goods": goods,
    },
    props: {},
    data() {
        return {
            selectAll: false,
            list: [],
            totalPrice: 0,
            empty: true
        };
    },
    watch: {
        list: {
            handler(val) {
                var totalPrice = 0;
                //未选中数量
                var notSelectNumber = 0;
                for (var item of val) {
                    if (item.select) {
                        totalPrice = this.NumberAdd(
                            totalPrice,
                            this.NumberMul(item.quantity, item.price)
                        );
                    } else {
                        notSelectNumber++;
                    }
                }
                this.selectAll = notSelectNumber == 0 ? true : false;
                this.totalPrice = Number(totalPrice);
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        cartList() {
            cartList({}).then((res) => {
                var list = res.data.data;
                for (var item of list) {
                    item.goodsPic = item.goodsPic
                        ? item.goodsPic.split(",")[0]
                        : [];
                    var goodsAttr = JSON.parse(item.goodsAttr);
                    var Specifications = [];
                    for (var im of goodsAttr) {
                        Specifications.push(im.v);
                    }
                    Specifications = Specifications.join("、");
                    item.Specifications = Specifications;
                    item.select = false;
                }
                this.list = list;
                if (this.list.length==0) {
                    this.empty = true
                } else {
                    this.empty = false
                }
            });
        },
        //全选
        allClick() {
            var select = false;
            if (this.selectAll) {
                select = false;
            } else {
                select = true;
            }
            this.selectAll = select;
            for (var item of this.list) {
                item.select = select;
            }
        },
        //移除购物车
        delCart(obj) {
            // on confirm
            this.list.splice(obj.index, 1);
            this.$toast.success({
                duration: 1500,
                message: "移除购物车",
            });
            delCart(obj.id).then((res) => {});
        },
        //结算
        settlement() {
            var orderInfo = {
                couponId: null,
                note: null,
                source: 0,
                userAddressId: null,
                name: null,
                phone: null,
                address: null,
                item: [],
            };
            localStorage.removeItem("orderInfo");
            for (var item of this.list) {
                if (item.select) {
                    item.goodsAttr = JSON.parse(item.goodsAttr);
                    item.specifications = [];
                    for (var im of item.goodsAttr) {
                        item.specifications.push(im.v);
                    }
                    item.specifications = item.specifications.join("、");
                    orderInfo.item.push({
                        cartId: item.id,
                        quantity: item.quantity,
                        skuId: item.goodsSkuId,
                        price: item.price,
                        name: item.goodsName,
                        pic: item.goodsPic,
                        specifications: item.specifications,
                    });
                }
            }
            if (orderInfo.item.length==0) {
                return this.$toast('请选择商品')
            }
            localStorage.setItem("orderInfo", JSON.stringify(orderInfo));
            this.$router.push({path: '/home/orderConfirm',query: {type: 'cart'}})
        },
    },
    created() {
        this.cartList();
    },
    mounted() {},
};
</script>
<style scoped>
.cart>>>.van-stepper{
    display: flex;
    align-items: center;
}
.cart {
    width: 100%;
    min-height: 100vh;
    padding-top: 0.7rem;
    box-sizing: border-box;
    padding-bottom: 2.5rem;
    overflow: hidden;
    background-color: RGBA(238, 238, 238, 1);
}
.bottom-btn-box {
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    height: 0.9rem;
    background: #fafafa;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
    z-index: 1000;
}
.select-box .icon {
    width: 0.36rem;
    height: 100%;
    margin-left: 0.3rem;
    object-fit: contain;
}
.select-box .txt {
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-left: 0.27rem;
}
.bottom-btn-box .btn-box {
    height: 100%;
}
.bottom-btn-box .btn-box .btn {
    width: 2.26rem;
    height: 100%;
    background: #f35e3d;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
}
.bottom-btn-box .money {
    margin-right: 0.3rem;
}
.bottom-btn-box .money .t .lb {
    font-size: 0.26rem;
    line-height: 0.28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
}
.bottom-btn-box .money .t .m {
    font-size: 0.3rem;
    line-height: 0.32rem;
    font-family: PingFang;
    font-weight: bold;
    color: #f36a4c;
}
.bottom-btn-box .money .t2 {
    font-size: 0.22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    line-height: 0.24rem;
    margin-top: 0.1rem;
}
.goods-nb {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    line-height: 0.7rem;
    box-sizing: border-box;
    padding-left: 0.3rem;
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
    z-index: 100;
    background-color: RGBA(238, 238, 238, 1);
}
.love-title {
    text-align: center;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-top: 0.5rem;
}
</style>