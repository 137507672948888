<template>
  <div class="goods flex jc-fd" @click="Click">
    <div
      class="goods-item flex ai-c"
       @touchstart="touchS" @touchmove="touchM" @touchend="touchE"
      :style="{left: x+'px'}"
    >
    <!-- <div
      class="goods-item flex ai-c"
       @touchstart="touchS" @touchmove="touchM" @touchend="touchE"
      :style="{transform: 'translateX('+x+'px)'}"
      
    > -->
        <img class="icon" @click.stop="goods.select=!goods.select" v-show="goods.select" src="/img/icon-passed.png" alt="">
        <img class="icon" @click.stop="goods.select=!goods.select" v-show="!goods.select" src="/img/icon-circle.png" alt="">
        <!-- <img class="goodsImg" :src="goods.goodsPic" alt=""> -->
        <van-image class="goodsImg" fit="cover" lazy-load :src="goods.goodsPic" />
        <div class="xq">
            <div class="goods-name line">
                {{goods.goodsName}}
            </div>
            <div class="specifications lines">
               {{goods.Specifications}}
            </div>
            <div class="money">¥{{goods.price.toFixed(2)}}</div>
        </div>
        <div class="stepper" @click.stop="">
            <van-stepper :integer="true" v-model.number="goods.quantity" />
        </div>
        
    </div>
    <div class="btn-box flex">
      <div class="btn flex ai-c jc-c" @click.stop="collectSave(goods.goodsId),x=0">加入收藏</div>
      <div class="btn flex ai-c jc-c" @click.stop="delClick({id:goods.id,index: index})">删除</div>
    </div>
  </div>
</template>

<script>
import { collectSave, cartChange } from '../../api'
export default {
  components: {},
  props: ["goods", "index"],
  data() {
    return {
        clientX1: '',
        clientX2: '',
        goodsX: 0,
        x: 0,

    };
  },
  watch: {
    'goods.quantity' (val) {
      this.cartChange(val)
    }
  },
  computed: {},
  methods: {
    cartChange(num) {
      var reg = /^[0-9a-zA-Z]+$/
      if (!reg.test(num)) {return}
      this.$toast.loading({
        duration: 0,
        forbidClick: true
      })
      cartChange({
        count: num,
        id: this.goods.goodsSkuId,
      }).then(res=>{

      }).finally(()=>{
        this.$toast.clear()
      })
    },
    Click() {
      if (this.x!=0) {
        this.x = 0
      } else {
        this.$router.push({path: '/home/details',query: {id: this.goods.goodsId}})
      }
      
    },
    // touch事件，显示删除
    touchS(e) {
      this.clientX1 = e.touches[0].clientX;
    },
    touchM(e) {
      this.clientX2 = e.touches[0].clientX;
      var x = this.clientX1 - this.goodsX - this.clientX2
      x = x<0?0:x
      this.x = -x
    },
    touchE(e) {
      var domW = document.getElementsByClassName('btn-box')[0].offsetWidth
      this.goodsX = -this.x>(domW/2)?-domW:0
      this.x = this.goodsX
    },
    delClick(obj) {
      this.$dialog.confirm({
            title: '提示',
            message: '确认移除该商品',
      }).then(() => {
        this.x=0
        this.$emit('delCart',obj)
      })
      
    },
    collectSave(id) {
      this.$toast.success({
          duration: 1500,
          message: '收藏成功'
      })
      collectSave({
        id: id
      }).then(res=>{

      })
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.goods {
  position: relative;
  width: 7.1rem;
  height: 2.7rem;
  background: #ffffff;
  border-radius: 0.14rem;
  overflow: hidden;
  margin-left: 0.2rem;
}
.goods-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: left 0.1s;
  /* transition-duration:0.1s; */
}
.btn-box {
  width: 3rem;
  height: 100%;
}
.btn-box .btn {
  width: 1.5rem;
  height: 100%;
  background-color: rgba(247, 162, 71, 1);
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.btn-box .btn:nth-child(2) {
  background-color: rgba(243, 61, 61, 1);
}
.goods-item .icon{
    width: .36rem;
    height: 100%;
    margin-left: .1rem;
    object-fit: contain;
}
.goods-item .goodsImg{
    width: 1.7rem;
    height: 1.7rem;
    margin-left: .25rem;
    border-radius: .04rem;
}
.goods-item .xq{
    width: 4.2rem;
    margin-left: .27rem;
}
.goods-item .goods-name{
    width: 100%;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
}
.goods-item .specifications{
    width: 100%;
    line-height: .56rem;
    font-size: .24rem;
    line-height: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-top: .1rem;
}
.goods-item .money{
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F35E3D;
    margin-top: .45rem;
}
.stepper{
    position: absolute;
    right: .1rem;
    bottom: .48rem;
}
.stepper>>>.van-stepper__minus,.stepper>>>.van-stepper__plus{
    width: .5rem;
    height: .45rem;
    background-color: #fff;
}
.stepper>>>.van-stepper__minus{
    border: 1px solid #999999;
    border-right: 0;
    border-radius: .06rem 0rem 0rem .06rem;
}
.stepper>>>.van-stepper__plus{
    border: 1px solid #999999;
    border-left: 0;
    border-radius: 0rem .06rem .06rem 0rem;
}
.stepper>>>.van-stepper__minus--disabled,.stepper>>>.van-stepper__plus--disabled{
    border-color: currentColor;
}
.stepper>>>.van-stepper__input{
    width: .8rem;
    height: .45rem;
    margin: 0;
    border: 1px solid #999999;
    background-color: #fff;
}
</style>